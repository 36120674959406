import React from 'react';
import Layout from '../components/layout';

const PrivacyPage = () => (
  <Layout>
    <h1>Privacy Policy</h1>
    <p>
      This privacy notice discloses the privacy practices for the Driblet mobile
      application. This privacy notice applies solely to information collected
      by the app. It will notify you of the following:
    </p>

    <ol>
      <li>
        What personally identifiable information is collected from you through
        the app, how it is used and with whom it may be shared.
      </li>
      <li>What choices are available to you regarding the use of your data.</li>
      <li>
        The security procedures in place to protect the misuse of your
        information.
      </li>
      <li>How you can correct any inaccuracies in the information.</li>
    </ol>
    <p>
      <strong>Information Collection, Use, and Sharing</strong>
    </p>
    <p>
      We do not collect or store any information about you on any servers. Basic
      account details are persisted to your device's local storage, and is never
      persisted anywhere else.
    </p>
    <p>
      As we never receive your personal information, we will not and cannot use
      that information in any way. We also cannot ever sell your data as we
      never have access to it.
    </p>
    <p>
      <strong>Your Access to and Control Over Information</strong>
    </p>
    <p>
      You have full control to be able to delete your data from your device
      simply by logging out of the app. This will erase all your basic account
      data. You can also uninstall the app.
    </p>
    <p>
      <strong>Security</strong>
    </p>
    <p>
      We take precautions to protect your information. All outgoing requests are
      done so as secure HTTPS requests. This ensures all data sent to
      DigitalOcean is transmitted in a secure way.
    </p>
    <p>
      If you feel that we are not abiding by this privacy policy, you should
      contact us immediately via email at{' '}
      <a
        style={{ color: '#0071e0', textDecoration: 'none' }}
        href="mailto:woodruffapps@gmail.com"
      >
        woodruffapps@gmail.com
      </a>
      .
    </p>
  </Layout>
);

export default PrivacyPage;
